.nav {
  border-bottom: 4px solid #193e72;
  background-color: #ffffff;
  height: 70px;
  padding-left: 0px !important;
  display: flex;
}

.HeaderBrand img {
  width: 100%;
  max-width: 250px;
  min-width: 175px;
  height: auto;
}

.HeaderTitle {
  text-align: center;
  padding: 0;
}

.Title h1 {
  font-size: 1.25rem !important;
  font-weight: bold;
  padding-top: 10px;
}

.navbar-text {
  color: #1b1b1b !important;
  font-weight: bold;
}

span.SignIn.navbar-text {
  position: absolute;
  right: 0;
  top: 10px;
}

p.SignOut {
  position: absolute;
  right: 0;
  top: 13px;
}

p.SignIn {
  position: absolute;
  right: 0;
  top: 20px;
}

button.signInButton {
  border: 1px solid #ea5d4e;
  border-radius: 100px;
  cursor: pointer;
  padding: 0.3em 1em;
  color: #193e72;
  font-size: 1rem;
  font-weight: 800;
  background-color: #fff;
  position: relative;
  top: -10px;
}

button.googleSignInButton {
  border: none;
  cursor: pointer;
  padding: 0.3em 1em;
  background-color: #fff;
  position: relative;
  top: -10px;
}

button.signOutButton {
  border: 1px solid #ea5d4e;
  border-radius: 100px;
  cursor: pointer;
  padding: 0.3em 1em;
  color: #193e72;
  font-size: 1rem;
  font-weight: 800;
  background-color: #fff;
  position: relative;
  left: -25px;
  margin-left: 50px;
}

button.signInButton:hover,
button.signOutButton:hover {
  color: #000;
  background-color: #ea5d4e;
}

@media only screen and (max-width: 992px) {
  .nav {
    height: auto;
  }
  .HeaderTitle {
    text-align: left;
    padding-left: 35px !important;
  }

  span.SignIn.navbar-text {
    top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .HeaderTitle {
    padding: 0 !important;
    text-align: left;
  }
  .HeaderBrand img {
    text-align: left;
  }

  span.SignIn.navbar-text {
    top: 10px;
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .nav {
    text-align: center;
  }

  .HeaderTitle {
    padding: 0 !important;
    text-align: center;
  }
  .HeaderBrand img {
    text-align: center;
    padding: 0 !important;
  }
  .navbar-brand {
    margin: 0 !important;
  }

  p.SignOut,
  p.SignIn {
    position: initial;
  }

  span.navbar-text {
    display: block;
  }

  button.signInButton {
    top: 0;
  }
}
