body {
  height: 100px;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

div#root {
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0;
}

.AppBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #1b1b1b;
  padding: 0 !important;
}

.MainRow {
  flex: 1 0 auto;
}
.SidebarCol {
  background: white;
}

.MainCol {
  padding-top: 100px;
  background-color: #eff1f3;
  padding-bottom: 50px;
}

.loaderDiv {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

@media only screen and (max-width: 992px) {
  .MainCol {
    padding-top: 0px;
  }
}
