@media only screen and (max-width: 576px) {
  .hints-accordion {
    display: none;
  }
}
.TitleCard.card {
  text-align: center;
  height: 70px;
  background-color: #dfe3e7 !important;
}

.TitleCard.card > .card-header {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 25px;
  background-color: #dfe3e7 !important;
  color: #193e72;
  border-bottom: none !important;
}

.MenuCard.card {
  background-color: #193e72;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid white !important;
  font-size: 0.875rem;
}

.switch-card.card {
  font-size: 1rem;
  font-weight: bold;
  background-color: #dfe3e7;
  color: #193e72;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  height: 49px;
}
.switch-header {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  vertical-align: middle;
}
.switch-body {
  font-size: 0.75rem;
  border-top: 1px solid #193e72;
}

.switch-card .card-body {
  text-align: left;
}

.support-link {
  text-decoration: none;
  color: #193e72 !important;
}

.support-link:hover {
  text-decoration: none !important;
}

.news-link {
  color: #193e72 !important;
}

.right-chev {
  margin-left: 10px;
  margin-bottom: 3px;
}

.menu-head img.arrow-icon.closed {
  margin-top: 4px;
}

.menu-head img.arrow-icon.opened {
  margin-top: 7px;
}

.arrow-icon {
  float: right;
  margin-top: 4px;
  margin-right: 3px;
}

.card {
  border-radius: 0 !important;
  border: none !important;
}

.Menu.card.card > .card-header {
  border: none;
  height: 40px;
}

.menu-head {
  font-size: 1rem;
}

.list-group-item {
  color: #193e72;
  border-radius: 0 !important;
}

.icon {
  float: right !important;
  padding-right: none;
  vertical-align: middle;
  padding-top: 5px;
  cursor: pointer;
}

.icon {
  transform: scale(1, 1);
  transition: 0.5s;
}
.icon:hover {
  transform: scale(1.5, 1.5);
}

.header-icon {
  float: left !important;
  padding-right: 15px;
  margin-top: 1px;
}

.popover-ul-link {
  text-decoration: none;
  list-style-type: none;
  padding: 5px;
}

.search-link {
  border: none;
  color: #193e72 !important;
  background-color: white;
  -webkit-appearance: none;
}

.search-link:hover {
  color: #193e72 !important;
  cursor: pointer;
  text-decoration: underline;
}

.save-search-link {
  border: none;
  color: #193e72 !important;
  padding-left: 7px !important;
  -webkit-appearance: none;
  background-color: white;
}

:focus {
  outline: none !important;
}

.save-search-link:hover {
  color: #193e72 !important;
  cursor: pointer;
}

.recent-search-group {
  display: flex !important;
  flex-wrap: wrap;
}

.search-time {
  flex: 1;
  font-size: 0.75rem;
}

.search-item {
  flex: 3;
  display: flex !important;
  flex-direction: column;
  min-width: 5%;
  overflow-wrap: break-word;
  margin-right: 5%;
}

.search-url {
  flex: 1;
  font-size: 0.75rem;
}

#tooltip-hintSwitch {
  margin-left: 20px;
  margin-top: 8px;
}

.switch-card .menu-head {
  padding: 0.75rem 1.25rem;
}
@media only screen and (max-width: 576px) {
  .switch-card.card {
    display: none;
  }
}