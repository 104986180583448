.aup-position {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.displayBlock {
  display: block !important;
}

.aup-button {
  margin: 20px;
  background-color: #193e72 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-color: #193e72 !important;
}

.AUP-frame {
  background-color: #193e72 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-color: #193e72 !important;
}

@media screen and (min-width: 1400px) {
  .AUP-frame {
    width: 800px;
    height: 700px;
  }
}

@media screen and (max-width: 1399px) {
  .AUP-frame {
    width: 700px;
    height: 500px;
  }
}

@media screen and (max-width: 1000px) {
  .AUP-frame {
    width: 550px;
    height: 500px;
  }
}

@media screen and (max-width: 860px) {
  .AUP-frame {
    width: 400px;
    height: 500px;
  }
}

@media screen and (max-width: 710px) {
  .AUP-frame {
    width: 350px;
    height: 500px;
  }
}

@media screen and (max-width: 580px) {
  .AUP-frame {
    width: 450px;
    height: 500px;
  }
}

@media screen and (max-width: 450px) {
  .AUP-frame {
    width: 250px;
    height: 400px;
  }
}
