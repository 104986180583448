.icon-tooltip > .tooltip-inner {
  background-color: #c1dde0;
  color: #193e72;
  font-size: 0.75rem;
  border-bottom: none;
  border-radius: 2px !important;
  margin: 0;
  padding: 0;
}

.icon-tooltip {
  opacity: 1 !important;
}

.icon-tooltip p {
  padding: 5px;
  margin: 0;
}

/* .hints-accordion {
  position: fixed;
  right: 0px;
  bottom: 55px;
} */

.header {
  border-bottom: 3px solid #193e72 !important;
  cursor: pointer;
  width: 80%;
  margin: auto;
}

.hide-tools.card {
  color: #353535;
  border: none;
}

.tools-text {
  text-align: center;
  color: #193e72;
  font-size: 0.875rem;
  padding-bottom: 10px;
  margin-top: 20px;
}
.tools-title {
  color: #353535;
  font-weight: bold !important;
  font-size: 1.125rem;
  margin-top: 20px;
  text-align: left;
}

button.header.btn.btn-link {
  text-decoration: none;
  padding: 0;
}

button.header.toggleTools.btn.btn-link {
  width: 80%;
}

.content {
  margin-top: 10px;
}

.iconButton .img {
  width: 64px;
}

.iconButton .img {
  width: 64px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.iconButton a {
  display: block;
  position: relative;
  height: 64px;
}

.desc {
  color: #353535;
  font-size: 0.75rem;
  text-align: center;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.chev {
  margin-left: 20px;
}

.react-switch {
  padding-left: 10px;
  top: 2px;
  float: right;
  left: 10px;
}

.switch-card label {
  width: 100%;
}

.empty-space {
  width: 64px;
  height: 64px;
  display: block;
  border: #dfe3e7 !important;
  background-color: #dfe3e7;
  border-radius: 60px;
  text-align: center;
  font-size: 2.625rem;
  color: #193e72 !important;
  font-family: "Courier";
}

.tooltip .arrow {
  display: none !important;
}

.Tool-container.container {
  padding-bottom: 10px;
}

.Tool-container .accordion {
  text-align: center;
}

/* ToolsDisplay */
.dropTarget {
  overflow: hidden;
  clear: both;
  position: relative;
  text-align: center;
}
/*  */

/* Icon */
.iconButton {
  cursor: move;
  position: relative;
  margin: 10px 4% 10px 4%;
  display: inline-block;
  width: 64px;
}

.iconButton.empty {
  pointer-events: none;
}

.empty p.desc {
  display: inherit;
}
/* */

.toolsStore {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media only screen and (max-width: 992px) {
  button.header.toggleTools.btn.btn-link {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .empty-space {
    font-size: 1.5rem;
  }
}

