.footer-link {
  text-decoration: none;
  color: #ffffff !important;
  display: block;
}

.footer-link:hover {
  text-decoration: none !important;
}

.text {
  float: center;
}

.copyright {
  float: right !important;
  padding-right: 30px;
}

.footer-text {
  text-align: center;
}

div#pagesCol {
  padding: 0 0px 0 20px;
}

.footer-content {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #193e72;
  color: #ffffff;
  font-size: 0.875rem;
  padding: 10px 0px;
  overflow: hidden;
}

.footer-content p {
  margin: 0;
}

@media only screen and (max-width: 992px) {
  .footer-content.no-gutters.row {
    height: auto;
  }
  div#descCol {
    padding-left: 20px;
    margin-top: 10px;
    padding-right: 10px;
  }

  .copyright {
    float: initial !important;
    margin-top: 10px;
  }
  .footer-text p {
    margin: 0;
  }
  .footer-text {
    text-align: left;
}
}

@media only screen and (max-width: 576px) {
  .footer-content.no-gutters.row {
    text-align: center;
  }

  .footer-content div {
    padding: 0;
    margin: 5px 0px;
  }

  .footer-content.no-gutters.row div {
    padding: 0 10px !important;
  }
  #copyrightCol .copyright {
    text-align: center;
  }
  .footer-text {
    text-align: center;
}
}
