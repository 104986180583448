.search {
  color: #193e72;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-width: 2px;
  border-color: #193e72;
}

.Search {
  color: #193e72;
  padding-bottom: 20px;
  margin-top: 73px;
}

.SearchButton {
  background-color: #193e72 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-color: #193e72 !important;
}

.SearchInput {
  color: #193e72;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-width: 2px;
  border-color: #193e72;
}

.SearchText {
  padding: 10px 20px;
  font-size: 0.875rem;
  text-align: center;
}

.SearchInput::placeholder {
  color: #193e72bd !important;
  font-size: 0.875rem;
  vertical-align: middle !important;
  padding-top: 5px;
}

input.SearchInput.form-control {
  border: solid #193e72;
  padding-bottom: 10px;
}

input.SearchInput.form-control {
  padding-left: 1rem;
}